import React from 'react';
import ContentGrid from '../content-grid/content-grid.component';

import * as styles from './footer.styles.module.scss';

interface FooterProps {
  author: string;
}

const Footer: React.FC<FooterProps> = ({ author }) => (
  <footer className={styles.footerContainer}>
    <ContentGrid>
      <div className={styles.footerMiddle}>
        <p>
          Disclaimer: The Coding Walrus is not coded or written by an actual
          walrus. If you happen upon a coding walrus for the job, let us know.
        </p>
        <p>
          © {new Date().getFullYear()}, Built by {author}
        </p>
        <p>
          All works in this site are under the{' '}
          <a
            href='https://creativecommons.org/licenses/by/4.0/'
            rel='noreferrer noopener'
          >
            Creative Commons Attribution 4.0 International (CC BY 4.0) License.
          </a>
        </p>
      </div>
    </ContentGrid>
  </footer>
);

export default Footer;
