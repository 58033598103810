import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetadataTypes } from '../../types/siteMetadata.types';
import { FileQueryType } from '../../types/allFile.types';

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: any[];
  title?: string;
  image?: any;
  type?: string;
  slug?: string;
  imageAlt?: string;
}

type Data = SiteMetadataTypes & FileQueryType;

const SEO: React.FC<SEOProps> = ({
  description,
  lang,
  meta = [],
  title,
  image,
  type,
  slug,
  imageAlt,
}) => {
  const data: Data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        file(name: { eq: "walrus-3d-roumd-png" }) {
          childImageSharp {
            fluid(maxHeight: 512, maxWidth: 512) {
              src
            }
          }
        }
      }
    `
  );

  const {
    site: { siteMetadata },
    file: { childImageSharp },
  } = data;

  const metaDescription = description ?? siteMetadata.description;

  const metaImage = image
    ? `${siteMetadata.siteUrl}${image}`
    : `${siteMetadata.siteUrl}${childImageSharp.fluid.src}`;

  const metaType = type ?? `website`;

  const metaUrl = slug
    ? `${siteMetadata.siteUrl}${slug}`
    : `${siteMetadata.siteUrl}/`;

  const metaImageAlt = imageAlt ?? `TCW Logo`;

  const metaLang = lang ?? `en`;

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={title}
      titleTemplate={`${
        title !== siteMetadata.title ? `%s | ${siteMetadata.title}` : `${title}`
      }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:alt`,
          content: metaImageAlt,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // {
        // 	name: `twitter:image`,
        // 	content: metaImage,
        // },
        //minutes read based on content
        // {
        // 	name: `twitter:data1`,
        // 	content: `calculation for minutes using word count`,
        // },
      ].concat(meta)}
    />
  );
};

// SEO.defaultProps = {
// 	lang: `en`,
// 	meta: [],
// 	description: ``,
// };

// SEO.propTypes = {
// 	description: PropTypes.string,
// 	lang: PropTypes.string,
// 	meta: PropTypes.arrayOf(PropTypes.object),
// 	title: PropTypes.string.isRequired,
// };

export default SEO;
