import { Link } from 'gatsby';
import React from 'react';

//style
import * as styles from './header.styles.module.scss';

const WalrusIcon = require('../../../static/walrus-3d-roumd.svg');

const Header: React.FC<HeaderProps> = ({ siteTitle }) => (
  <header className={styles.header}>
    <Link to='/' className={styles.link}>
      <img src={WalrusIcon} alt='Walrus Icon' />
      <p>{siteTitle}</p>
    </Link>
  </header>
);

interface HeaderProps {
  siteTitle: string;
}

// Header.propTypes = {
// 	siteTitle: PropTypes.string,
// };

// Header.defaultProps = {
// 	siteTitle: ``,
// };

export default Header;
