import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../header/header.component';
import Footer from '../footer/footer.component';

import './layout.module.scss';

//types
import { SiteMetadataTypes } from '../../types/siteMetadata.types';

const Layout: React.FC<React.ReactNode> = ({ children }) => {
  const data: SiteMetadataTypes = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  const { title, author } = data.site.siteMetadata;

  return (
    <>
      <Header siteTitle={title} />
      {children}
      <Footer author={author} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
